import React from "react";
import Lottie from "react-lottie-player";
import lottieJson from "../assets/images/anima.json";

function LivelinessCapture({ onStartLiveliness, passiveError, passivecamon, passiveLoading, passiveFail, passiveData, facematchCondition, liveMatch, liveMatchLoaded }) {
	return (
		<>
			{!passivecamon && (
				<>
					<Lottie loop animationData={lottieJson} play style={{ width: "35%", height: "35%" }} />
					{!passiveLoading ? (
						<>
							{passiveFail ? (
								<>
									{" "}
									Liveliness failed...<br></br>
								</>
							) : null}
							<button className="btn btn-primary btn-lg" onClick={onStartLiveliness}>
								{!passiveFail ? <>Liveliness</> : <>Retry Liveliness</>}
							</button>
						</>
					) : (
						<button className="btn btn-primary btn-lg" disabled>
							Processing...
						</button>
					)}
					{passiveData && (
						<>
							<br></br>
							<div className="errormolo alert alert-success d-flex align-items-center" role="alert">
								Liveliness Result: {passiveData.LivenessAssessment === "NotLive" ? <>Failed</> : <>Pass</>}
							</div>

							{facematchCondition && (
								<div className="errormolo alert alert-success d-flex align-items-center" role="alert">
									<div>
										<small>Please wait...</small>
										<br />
										<small>Analysing and matching document and selfie image</small>
									</div>
								</div>
							)}
							{passiveError && (
								<div className="errormolo alert alert-danger d-flex align-items-center" role="alert">
									<div>
										Details: <br />
										{passiveError}
									</div>
								</div>
							)}
						</>
					)}
				</>
			)}

			{!liveMatch && liveMatchLoaded && (
				<div className="errormolo alert alert-danger d-flex align-items-center" role="alert">
					<div>
						Document Match Failed : <span className="spa">Photo in the provided document and Selfie did not match. Please try again</span>
					</div>
				</div>
			)}
		</>
	);
}

export default LivelinessCapture;
