import React from "react";

function ResultDisplay({ loading, success, message, illustrationSrc }) {
	return (
		<div className="d-flex flex-column justify-content-center">
			{loading ? (
				<>
					<div className="errormolo align-items-center">
						<h4>Analyzing data , Please wait .. </h4>
						<img className="illu1" src="/images/analysis.gif" alt="" />
					</div>
				</>
			) : success ? (
				<p className="mtext322">{message}</p>
			) : (
				<div className="errormolo alert alert-danger d-flex align-items-center" role="alert">
					<div>{message}</div>
				</div>
			)}
		</div>
	);
}

export default ResultDisplay;
