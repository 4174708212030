import React from "react";
import { useScript } from "react-use-scripts";

function CameraCapture({ onStartCapture, onCapture, onCaptureError, buttonText, illustrationSrc }) {
	return (
		<div className="d-flex flex-column justify-content-center">
			<img className="illu1" src={illustrationSrc} alt="" />
			<button onClick={onStartCapture} className="btn btn-primary btn-lg">
				{buttonText}
			</button>
		</div>
	);
}

export default CameraCapture;
