import React, { useState, useEffect } from "react";
import useScript from "react-use-scripts";
import ApiService from "./services/api";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { isMobile } from "react-device-detect";
import CameraCapture from "./Components/CameraCapture";
import ResultDisplay from "./Components/ResultDisplay";
import LivelinessCapture from "./Components/LivelinessCapture";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var qs = require("qs");
//quick hack job for presentation
//TO DO
//Move each step to a new route
//reorganzie functions and classes
//setup env
//use oauth instead of basic auth

function App() {
	const [startTrigger, setStartTrigger] = React.useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	const { isConfigScriptLoaded, isConfigScriptLoadedError } = useScript({
		src: "acuantConfig.js",
		onReady: () => console.log("config ready!"),
		onError: (error) => console.log("Config Error has happened!", error),
		startTrigger,
	});

	const { isSdkScriptLoaded, sdkScriptError } = useScript({
		src: "AcuantJavascriptWebSdk.min.js",

		onReady: () => console.log("sdk ready!"),
		onError: (error) => console.log("sdk error has happened!", error),
		startTrigger,
	});

	const { isCameraScriptLoaded, CameraScriptError } = useScript({
		src: "AcuantCamera.min.js",
		onReady: () => console.log("camera ready!"),
		onError: (error) => console.log("camera error has happened!", error),
		startTrigger,
	});

	const { isLivenessScriptLoaded, LivenessScriptError } = useScript({
		src: "AcuantPassiveLiveness.min.js",
		onReady: () => console.log("Liveliness ready!"),
		onError: (error) => console.log("Liveliness error has happened!", error),
		startTrigger,
	});

	const { isOpenCVScriptLoaded, isOpenCVScriptError } = useScript({
		src: "opencv.min.js",
		onReady: () => console.log("OpenCV ready!"),
		onError: (error) => console.log("OpenCV error has happened!", error),
		startTrigger,
	});

	const { isQRCodeScriptLoaded, isQRCodeScriptError } = useScript({
		src: "html5-qrcode.min.js",
		onReady: () => {
			console.log("HTMLQR ready!");
			setStartTrigger(false);
		},
		onError: (error) => console.log("HTMLQR error has happened!", error),
		startTrigger,
	});

	useEffect(() => {
		if (!startTrigger) {
			setStartTrigger(true);
		}
	}, [startTrigger]);

	const [SDKLoaded, setSDKLoaded] = useState(false);
	const [loading, setloading] = useState(false);
	const [passivecamon, setpassivecamon] = useState(false);
	const [passiveLoading, setpassiveLoading] = useState(false);
	const [passiveData, setpassiveData] = useState();
	const [passiveError, setpassiveError] = useState();
	const [passiveFail, setpassiveFail] = useState();
	const [passiveTransID, setpassiveTransID] = useState();
	const [AInstanceID, setAInstanceID] = useState();
	const [AssureFail, setAssureFail] = useState(false);
	const [AssureFailMsg, setAssureFailMsg] = useState([]);
	const [liveCaptureFailed, setliveCaptureFailed] = useState(false);
	const [userName, setuserName] = useState(null);
	const [step, setstep] = useState(0);
	const [cardType, setcardType] = useState(null);

	const [isAuth, setisAuth] = useState(false);
	const [idFront, setidFront] = useState(null);
	const [idBack, setidBack] = useState(null);
	const [Passport, setpassport] = useState(null);
	const [passiveImg, setpassiveImg] = useState(null);
	const [DocImg, setDocImg] = useState(null);
	const [liveMatch, setliveMatch] = useState(false);
	const [liveMatchLoaded, setliveMatchLoaded] = useState(false);
	const [AssureResults, setAssureResults] = useState(false);
	const [SUResults, setSUResults] = useState(false);
	const [SUResultsLoaded, setSUResultsLoaded] = useState(false);
	const [facematchCondition, setfacematchCondition] = useState(false);
	const [authKey, setAuthKey] = useState(null);
	const [authFailM, setauthFailM] = useState(false);
	const [sdkLoaderFailM, setsdkLoaderFailM] = useState(false);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const Id = searchParams.get("id");

	setTimeout(() => {
		if (window.loadAcuantSdk) window.loadAcuantSdk();
		onAcuantSdkLoaded();
	}, 200);

	useEffect(() => {
		setAuthKey(Id);
	}, [Id]);

	useEffect(() => {
		if (authKey) {
			LoadAuth();
		}
	}, [authKey]);

	function onAcuantSdkLoaded() {
		//Unexpected errors shouldn't happen with correct usage of the sdk, and should be fine to leave out,
		//but to be safe you can handle it with a callback function

		init();
	}

	function LoadAuth() {
		const data = qs.stringify({
			id: authKey,
		});

		const options = {
			method: "POST",
			headers: { "content-type": "application/x-www-form-urlencoded" },
			data: data,
			url: process.env.REACT_APP_vc,
		};

		axios(options)
			.then((response) => {
				if (response.data.status === "true") {
					setisAuth(true);
					setuserName(response.data.email);
				} else {
					setauthFailM(true);
				}
			})
			.catch((err) => {
				setauthFailM(true);
				toast("Unable to verify key. ERROR: " + err);
			});
	}

	function init() {
		const base64Token = btoa(process.env.REACT_APP_username + ":" + process.env.REACT_APP_pass);

		AcuantJavascriptWebSdk.initialize(base64Token, process.env.REACT_APP_endpoint, {
			onSuccess: function () {
				if (!isOldiOS()) {
					AcuantJavascriptWebSdk.startWorkers(initDone);
				} else {
					AcuantJavascriptWebSdk.startWorkers(initDone, [AcuantJavascriptWebSdk.ACUANT_IMAGE_WORKER]);
				}
			},
			onFail: function (code, description) {
				setsdkLoaderFailM(true);
				console.log(code);
				console.log(description);
			},
		});

		setSDKLoaded(true);
	}

	function isOldiOS() {
		let ua = navigator.userAgent;
		let keyPhrase = "iPhone OS";
		const keyPhrase2 = "iPad; CPU OS";
		let index = ua.indexOf(keyPhrase);
		if (index < 0) {
			keyPhrase = keyPhrase2;
			index = ua.indexOf(keyPhrase);
		}
		if (index >= 0) {
			let version = ua.substring(index + keyPhrase.length + 1, index + keyPhrase.length + 3);
			try {
				let versionNum = parseInt(version);
				if (versionNum && versionNum < 13) {
					return true;
				} else {
					return false;
				}
			} catch (_) {
				return false;
			}
		} else {
			return false;
		}
	}

	//Good practice to end workers when you are done with them (does not have to be in onbeforeunload, end them when you are done with them in your workflow and won't use them again).
	window.onbeforeunload = function (event) {
		end();
	};

	function initDone() {}

	var cameraCallback = {
		onCaptured: onCaptured,
		onCropped: onCropped,
		onFrameAvailable: function (response) {},
		onError,
	};

	const options = {
		text: {
			NONE: "ALIGN",
			SMALL_DOCUMENT: "MOVE CLOSER",
			GOOD_DOCUMENT: null, //null countdown
			BIG_DOCUMENT: "TOO CLOSE",
			CAPTURING: "CAPTURING",
			TAP_TO_CAPTURE: "TAP TO CAPTURE",
		},
	};

	function onCropped(response) {
		if (response && response.image) {
			if (response.cardType == 1 && step == 0) {
				setcardType(response.cardType);
				setidFront(response.image);
				setstep(1);
			}

			if (response.cardType == 1 && step == 1) {
				setidBack(response.image);
				// if (response.isPortraitOrientation) {
				//   setisPortraitOrientation(1)
				// }
				getIdoc();
				setstep(2);
			}

			if (response.cardType == 2 && step == 0) {
				setcardType(response.cardType);
				setpassport(response.image);
				// if (response.isPortraitOrientation) {
				//   setisPortraitOrientation(1)
				// }
				setstep(2);
				getIdoc();
			}
		} else {
			toast("We had issues cropping document , please make sure uploading a document ");
		}
	}

	function onCaptured(response) {
		CameraDiv.style.display = "none";
		RootDiv.style.display = "block";
	}

	function startCamera() {
		if (AcuantCamera.isCameraSupported && !liveCaptureFailed) {
			AcuantCameraUI.start(cameraCallback, options);
			RootDiv.style.display = "none";
			CameraDiv.style.display = "block";
		} else {
			startManualCapture();
		}
	}

	function startManualCapture() {
		AcuantCamera.startManualCapture(cameraCallback);
	}

	const faceDetectionState = {
		FACE_NOT_FOUND: "FACE NOT FOUND",
		TOO_MANY_FACES: "TOO MANY FACES",
		FACE_ANGLE_TOO_LARGE: "FACE ANGLE TOO LARGE",
		PROBABILITY_TOO_SMALL: "PROBABILITY TOO SMALL",
		FACE_TOO_SMALL: "FACE TOO SMALL",
		FACE_CLOSE_TO_BORDER: "TOO CLOSE TO THE FRAME",
	};

	const openFaceCaptureCamera = () => {
		faceDetectionTextDiv.innerHTML = "LOADING DETECTOR";
		faceCaptureContainer.style.display = "block";

		const onFaceCaptured = (image) => {
			setpassiveLoading(true);
			setpassivecamon(true);
			faceDetectionTextDiv.style.display = "none";
			faceCaptureContainer.style.display = "block";

			setpassiveImg(image);

			AcuantPassiveLiveness.getLiveness(
				{
					endpoint: process.env.REACT_APP_liveendpoint,
					token: btoa(process.env.REACT_APP_username + ":" + process.env.REACT_APP_pass),
					image: image,
					subscriptionId: process.env.REACT_APP_subsc,
				},
				async (result) => {
					if (result) {
						if (result.LivenessResult) {
							const mResult = await result;
							setpassiveData(mResult.LivenessResult);
							setpassiveTransID(mResult.TransactionId);
							setpassiveLoading(false);
							if (mResult.LivenessResult.LivenessAssessment == "Live") {
								setpassiveFail(false);
							}
						} else {
							const mResult = await result;
							setpassiveError(mResult.Error);
							setpassiveFail(true);
							setpassiveLoading(false);
						}
					} else {
						setpassiveFail(true);
						setpassiveLoading(false);
					}
				}
			);
		};

		let handler = {
			onDetectorInitialized: () => {
				faceDetectionTextDiv.innerHTML = "";
			},
			onDetection: (text) => {
				faceDetectionTextDiv.innerHTML = text;
			},
			onOpened: () => {
				faceDetectionTextDiv.style.display = "flex";
			},
			onClosed: () => {
				faceCaptureContainer.style.display = "none";
				faceDetectionTextDiv.style.display = "none";
				setpassivecamon(false);
			},
			onError: (error) => {
				if (error) {
					//camera permission not granted
					setpassivecamon(false);
					faceDetectionTextDiv.style.display = "none";
					faceCaptureContainer.style.display = "block";
					toast(error.message);
				}
			},
			onPhotoTaken: () => {
				faceDetectionTextDiv.style.display = "none";
			},
			onPhotoRetake: () => {
				faceDetectionTextDiv.style.display = "flex";
			},
			onCaptured: onFaceCaptured,
		};

		AcuantPassiveLiveness.start(handler, faceDetectionState);
	};

	function onError(error, code) {
		toast("Error :" + error + " " + "Code: " + code);
	}

	function dataURLtoBlob(dataURL) {
		var binary = atob(dataURL.split(",")[1]);

		var array = [];
		for (var i = 0; i < binary.length; i++) {
			array.push(binary.charCodeAt(i));
		}

		return new Blob([new Uint8Array(array)], { type: "image/jpg" });
	}

	function getIdoc() {
		if (!AInstanceID) {
			ApiService.getDocInstance()
				.then((response) => {
					setAInstanceID(response);
				})
				.catch((err) => {});
		}
	}

	function SendImageAPI() {
		if (AInstanceID && cardType == 2) {
			setloading(true);
			ApiService.postFrontImage(AInstanceID, dataURLtoBlob(Passport.data))
				.then((response) => {
					console.log("passport");
					console.log("NO error");

					Classify();
				})
				.catch((err) => {
					console.log("passport");
					console.log("NO error");
					console.log(err);
					setloading(false);
				});
		}
	}

	function SendImageAPI2() {
		if (AInstanceID && cardType == 1) {
			setloading(true);

			ApiService.postFrontImage(AInstanceID, dataURLtoBlob(idFront.data))
				.then((response) => {
					console.log("IDCARD");
					console.log("NO error");
				})
				.catch((err) => {
					console.log("IDCARD");
					console.log("Error");
					console.log(err);
					setloading(false);
				});

			ApiService.postBackImage(AInstanceID, dataURLtoBlob(idBack.data))
				.then((response) => {
					console.log("IDCARD");
					console.log("NO error");
					Classify();
				})
				.catch((err) => {
					console.log("IDCARD");
					console.log("Error");
					console.log(err);
					setloading(false);
				});
		}
	}

	function Classify() {
		if (AInstanceID) {
			ApiService.getClassification(AInstanceID)
				.then((response) => {
					console.log("Classification Done");
					GetFinalResult();
				})
				.catch((err) => {
					alert(err);
				});
		}
	}

	function GetFinalResult() {
		if (AInstanceID) {
			ApiService.getResults(AInstanceID)
				.then(async (response) => {
					if (response.Result == 1) {
						const results = await response;
						setloading(false);
						setAssureResults(results);
						GetIDface();
						setstep(3);
					} else {
						if (response.Result != 1 && response.Alerts.length > 0) {
							const results = await response;
							setAssureFailMsg(results.Alerts);
							setAssureFail(true);
							setloading(false);
						}
					}
				})
				.catch((err) => {
					console.log(err);
					setAssureFail(true);
					setloading(false);
				});
		}
	}

	function refreshPage() {
		window.location.reload(false);
	}

	function sendFinalVeri() {
		let data = qs.stringify({
			auth: authKey,
			instance_id: AInstanceID,
		});

		const options = {
			method: "POST",
			headers: { "content-type": "application/x-www-form-urlencoded" },
			data: data,
			url: "https://api.shallunite.com/api/verify",
		};

		axios(options).then(
			(response) => {
				if (response.status) {
					setSUResults(true);
					setSUResultsLoaded(true);
				} else {
					setSUResultsLoaded(true);
					setAssureResults(true);
				}
			},
			(error) => {
				setSUResultsLoaded(true);
			}
		);
	}

	function GetIDface() {
		if (AInstanceID && !DocImg) {
			ApiService.getFaceImage(AInstanceID)
				.then(async (response) => {
					const mm = await response;
					setDocImg(mm);
				})
				.catch((err) => {});
		} else {
			setTimeout(GetIDface(), 100);
		}
	}

	async function getFaceMatch() {
		if (DocImg && passiveImg) {
			const base64DocImage = btoa(String.fromCharCode(...new Uint8Array(DocImg)));

			const body = {
				Settings: {
					SubscriptionId: process.env.REACT_APP_subsc,
				},
				Data: {
					ImageOne: base64DocImage,
					ImageTwo: passiveImg,
				},
			};

			let AUTH_TOKEN = btoa(`${process.env.REACT_APP_username}:${process.env.REACT_APP_pass}`);

			try {
				const response = await fetch("https://aus.frm.acuant.net/api/v1/facematch", {
					method: "POST",
					body: JSON.stringify(body),
					headers: {
						"Content-Type": "application/json",
						Authorization: "Basic " + AUTH_TOKEN,
						Accept: "application/json",
					},
				});

				const myJson = await response.json();
				let dataObject = {};

				for (var Key in myJson) {
					dataObject[Key] = myJson[Key];
				}

				if (dataObject.IsMatch) {
					setfacematchCondition(false);
					setliveMatchLoaded(true);
					setliveMatch(true);
					setstep(4);
					sendFinalVeri();
				} else {
					setliveMatchLoaded(true);
					setfacematchCondition(false);
				}
			} catch (e) {
				setliveMatchLoaded(true);
				setfacematchCondition(false);
			}
		}
	}

	const faceCaptureContainer = document.getElementById("acuant-face-capture-container");
	const faceDetectionTextDiv = document.getElementById("face-detection-text");
	const CameraDiv = document.getElementById("acuant-camera");
	const RootDiv = document.getElementById("mojoK");
	useEffect(() => {
		if (DocImg && passiveImg) {
			setfacematchCondition(true);
			getFaceMatch();
		}
	}, [passiveData]);

	// if (isMobile){

	return (
		<>
			<div id="mojoK">
				<nav className="navbar bg-light">
					<div className="container-fluid">
						<a className="navbar-brand" href="#">
							<img className="mobilehide" src="/images/su-logo.svg" alt="" />
							<img className="mobileshow smallLogo" src="/images/small-logo.png" alt="" />
						</a>
						<span className="navbar-brand mb-0 h1">Identity Verification</span>
						{userName ? (
							<>
								<span>
									{" "}
									<small>User : {userName}</small>
								</span>
							</>
						) : null}
					</div>
				</nav>
				<div id="Mainbox" className="MainBox h-100 d-flex align-items-center justify-content-center">
					{step === 0 && isAuth ? <CameraCapture onStartCapture={() => startCamera()} onCapture={() => onCaptured()} onCaptureError={(error) => onError(error)} buttonText="Capture ID/Passport" illustrationSrc="/images/scan_process.gif" /> : null}
					{step === 1 && isAuth ? <CameraCapture onStartCapture={() => startCamera()} onCapture={() => onCaptured()} onCaptureError={(error) => onError(error)} buttonText="Capture Other Side" illustrationSrc="/images/IDback@3x.png" /> : null}
					{step === 2 && isAuth ? (
						<ResultDisplay
							loading={loading}
							success={AInstanceID && !AssureFail}
							message={
								cardType === 2 && !AssureFail ? (
									<>
										<div className="d-flex flex-column justify-content-center">
											<h5>Document Type: Passport</h5>
											<img className="illu2" src={Passport.data} alt="" />
											{loading ? (
												<img className="illu1" src="/images/analysis.gif" alt="" />
											) : (
												<button className="btn btn-primary btn-lg" onClick={(e) => SendImageAPI(e)}>
													{!loading ? <>Confirm</> : <>Analyzing.....</>}
												</button>
											)}
										</div>
									</>
								) : cardType === 1 && !AssureFail ? (
									<>
										<div className="d-flex flex-column justify-content-center">
											<h5>Document Type: ID Card</h5>
											<img className="illu2" src={idFront.data} alt="" />
											<img className="illu2" src={idBack.data} alt="" />
											{loading ? (
												<img className="illu1" src="/images/analysis.gif" alt="" />
											) : (
												<button className="btn btn-primary btn-lg" onClick={(e) => SendImageAPI2(e)}>
													{!loading ? <>Confirm</> : <>Analyzing.....</>}
												</button>
											)}
										</div>
									</>
								) : loading ? (
									<div className="errormolo align-items-center">
										<h4>Analyzing data , Please wait .. </h4>
										<img className="illu1" src="/images/analysis.gif" alt="" />
									</div>
								) : AssureFail ? (
									<>
										{" "}
										<h5>Document check failed</h5>
										<div className="d-flex flex-column justify-content-center">
											<div>
												<h1>Issues</h1>
												{AssureFailMsg.map((alert, index) => {
													if (alert.Disposition != "No evidence of document tampering was detected.")
														return (
															<div key={index}>
																<p>
																	{index + 1}
																	{" ) "}
																	{alert.Disposition}
																</p>
																<hr />
															</div>
														);
												})}
											</div>

											<p>Please review and address the issues mentioned above.</p>
											<h3>Consider the following suggestions:</h3>
											<ul>
												<li className="mose">Take the picture in a well-lit environment for better clarity.</li>
												<li className="mose">Ensure your document is undamaged and free from any tampering.</li>
												<li className="mose">If the document type cannot be determined, it may not be supported. Please try an alternative document.</li>
											</ul>
											<button className="btn btn-primary btn-lg" onClick={refreshPage}>
												Retry
											</button>
										</div>
									</>
								) : null
							}
						/>
					) : null}

					{step === 3 && isAuth ? (
						<LivelinessCapture
							onStartLiveliness={() => openFaceCaptureCamera()}
							{...{
								passiveError,
								passivecamon,
								passiveLoading,
								passiveFail,
								passiveData,
								facematchCondition,
								liveMatch,
								liveMatchLoaded,
							}}
						/>
					) : null}

					{step === 4 && isAuth ? (
						<ResultDisplay
							loading={!SUResultsLoaded}
							success={SUResults}
							message={
								SUResultsLoaded ? (
									<>
										<p className="mtext322">{SUResults ? "You have successfully verified, now you can enjoy unrestricted access to all ShallUnite features." : "We are processing the final checks, and we will get back to you with results in a moment."}</p>
										<ul>
											<li className="checked">Valid Document Type</li>
											<li className="checked">Document Validation</li>
											<li className="checked">Liveliness</li>
											<li className="checked">ID / Passport to Liveliness match</li>
											<li className={SUResults ? "checked" : "unchecked"}>Validation</li>
											<li className={SUResults ? "checked" : "unchecked"}>Overall Results</li>
										</ul>
										{!SUResultsLoaded && <img className="loader" src="/images/loader.png" alt="" />}
										<br />
										<a href="https://shallunite.com">Go back to ShallUnite</a>
									</>
								) : null
							}
						/>
					) : null}
					{!SDKLoaded ? <div className="errormolo d-flex align-items-center">Loading...</div> : null}

					{SDKLoaded && !isAuth ? <div className="errormolo d-flex align-items-center">Invalid Session</div> : null}

					{(isAuth && sdkLoaderFailM) || (!authFailM && sdkLoaderFailM) ? <div className="errormolo  d-flex align-items-center">Unable to load application correctly , this might due to internet issue , please refresh the page</div> : null}
				</div>
			</div>

			<ToastContainer />
		</>
	);
}

export default App;
